<template>
  <div class="plan-add">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center">
        学习规划系统
      </div>
      <div class="right"></div>
    </div>
    <div class="title-block">
      <div class="title" v-if="req.hasFinish == false">
        <img :src="todoIcon" />
        <div class="title-input">{{ req.title }}</div>
        <img :src="editIcon" @click="onEdit()" />
      </div>
      <div class="title" v-else>
        <img :src="todoDoneIcon" />
        <div class="title-input title-del">{{ req.title }}</div>
        <img :src="editIcon" @click="onEdit()" />
      </div>
    </div>
    <div class="main-block">
      <div class="main">
        <div class="datetime-block">
          <div class="left">
            <img :src="timeIcon" />
            <span class="label"> 开始日期 </span>
          </div>
          <div class="right">
            <div class="calendar-val">
              <div>{{ startDateText }}</div>
            </div>
          </div>
        </div>

        <div class="datetime-block">
          <div class="left">
            <img :src="timeIcon" />
            <span class="label"> 结束日期 </span>
          </div>
          <div class="right">
            <div class="calendar-val">
              <div>{{ endDateText }}</div>
            </div>
          </div>
        </div>
        <div class="text-block">
          <div class="plan-text">{{ req.planText }}</div>
        </div>
        <div class="upload-block">
          <!-- <upload v-model="req.attach" :readonly="true" :bizType="bizType"> </upload> -->
          <aidaUpload v-model="req.attach" :readonly="true" :bizType="bizType" :isSingleFile="false" :maxSize="100">
          </aidaUpload>
        </div>
      </div>
    </div>
    <div class="audit-block">
      <div class="text-info">
        <div class="info" v-for="(item, index) in req.logList" :key="index">{{ item }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { userLearnPlanApi } from "@/api/userLearnPlanApi.js";
import { Toast, Notify } from "vant";
import aidaUpload from "@/components/aidaUpload.vue";
import dayjs from "dayjs";
import { matchFileType } from "@/util/validate";
export default {
  components: {
    aidaUpload,
  },
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
      todoIcon: require("@/assets/images/user-learn-plan/todo.png"),
      editIcon: require("@/assets/images/user-learn-plan/edit.png"),
      todoDoneIcon: require("@/assets/images/user-learn-plan/todo-done.png"),
      timeIcon: require("@/assets/images/user-learn-plan/date.png"),
      bizType: "user_learn_plan",
      id: 0,

      req: {
        id: 0,
        title: "",
        planText: "",
        startTime: 0,
        endTime: 0,
        hasFinish: false,
        attach: [],
      },

      startDateText: "",
      endDateText: "",
    };
  },
  methods: {
    initDbData(id) {
      userLearnPlanApi
        .get(id)
        .then((ret) => {
          //console.log(ret);
          let { code, data } = ret;
          if (code == 0 && data) {
            this.wrapAttach(data.attach);
            this.req = data;
            this.epochToStr();
            this.wrapAudit(data.audit);
          }
        })
        .catch((err) => {
          Notify({
            type: "danger",
            message: "系统出错，请联系客服" + err,
          });
        });
    },
    wrapAudit(auditList) {
      let logList = [];
      for (let i = auditList.length - 1; i >= 0; i--) {
        const day = dayjs(Number(auditList[i].actTime));
        const dayStr = day.format("YYYY.MM.DD");
        logList.push(dayStr + " " + auditList[i].actLog + "规划");
      }
      this.req.logList = logList;
    },
    onEdit() {
      this.$router.push({
        path: "/userLearnPlan/addEdit",
        query: {
          id: this.id,
        },
      });
    },
    onGoBack() {
      this.$router.push({
        path: "/userLearnPlan",
      });
    },
    wrapAttach(itemList) {
      for (let item of itemList) {
        if (item.fileType) {
          continue;
        }
        item.fileType = matchFileType(item.key);
      }
    },

    epochToStr() {
      let cur = dayjs(Number(this.req.startTime));
      let next = dayjs(Number(this.req.endTime));

      this.startDateText = cur.format("YYYY-MM-DD");
      this.endDateText = next.format("YYYY-MM-DD");
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id;
      this.initDbData(this.id);
    } else {
      Notify({
        type: "warning",
        message: "缺少id参数",
      });
    }
  },
  destroyed() {},
  filters: {},
  watch: {},
};
</script>

<style lang="less" scoped>
.plan-add {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .header {
    height: 62px;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 20px;
      font-weight: 500;
      color: #323233;
      letter-spacing: 0.44px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
    }
    .right {
      width: 27px;
    }
  }
  .title-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      width: 344px;
      height: 44px;
      background: #ffffff;
      border-radius: 8px;
      margin: 14px 0px 0px 0px;

      display: flex;
      flex-direction: row;
      img {
        height: 16px;
        width: 16px;
        margin: 14px 12px 10px 12px;
      }
      .title-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 300px;
        font-size: 16px;
        color: #c3c3c3;
        letter-spacing: 0.36px;
        border: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .title-del {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: line-through;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .main-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main {
      background: #ffffff;
      border-radius: 8px;
      width: 344px;
      margin: 6px 0px 0px 0px;
      display: flex;
      flex-direction: column;
      .datetime-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 42px;
        font-size: 16px;
        margin: 0 12px;
        color: #505051;
        letter-spacing: 0.36px;
        border-bottom: 1px solid rgb(242, 242, 242);
        .left {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 16px;
          color: #505051;
          letter-spacing: 0.36px;
          img {
            height: 16px;
            width: 16px;
            margin: 12px 12px 12px 0px;
          }
        }
        .right {
          display: flex;
          flex-direction: row;
          align-items: center;
          .calendar-val {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 96px;
            background: #efefef;
            border-radius: 8px;
            font-size: 14px;
            color: #323233;
            letter-spacing: 0.31px;
            margin: 0px 4px 0px 0px;
          }
          .time-val {
            width: 50px;
          }
        }
      }
      .text-block {
        margin: 14px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .plan-text {
          height: 132px;
          width: 318px;
          font-size: 14px;
          color: #c3c3c3;
          letter-spacing: 0.31px;
          overflow: hidden;
          overflow-y: scroll;
          border: none;
          resize: none;
        }
      }
      .upload-block {
        margin: 12px;
      }
    }
  }
  .audit-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    overflow-y: scroll;
    .text-info {
      width: 344px;
      margin: 40px 0px 0px 0px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      overflow-y: scroll;
      .info {
        font-size: 11px;
        color: #9298a4;
        letter-spacing: 0.24px;
        margin: 0px 0px 18px 0px;
      }
    }
  }
}
</style>
